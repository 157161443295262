import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from '../../../theme/global-constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer-global',
  templateUrl: './footer-global.component.html',
  styleUrls: ['./footer-global.component.css']
})
export class FooterGlobalComponent implements OnInit {
  appName = GlobalConstants.appName;
  constructor(private router: Router) { }

  ngOnInit() {
  }

  goToPrivacyPolicy(){
    this.router.navigate(['support/privacy-policy']);
  }
  goToTermsAndConditions(){
    this.router.navigate(['support/terms-and-conditions']);
  }

}
