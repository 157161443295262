import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-header-home-user-not-login',
  templateUrl: './header-home-user-not-login.component.html',
  styleUrls: ['./header-home-user-not-login.component.css']
})
export class HeaderHomeUserNotLoginComponent implements OnInit {

  constructor(private router: Router, private dataProvider: DataService) { }

  ngOnInit() {
  }

  goToPrivacyPolicy(){
    this.router.navigate(['support/privacy-policy']);
  }

  goToHome(){
        this.router.navigate(['/home']);
  }

}
