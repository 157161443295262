import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { DataService } from '../app/services/data.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent {
  user: any;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private afAuth: AngularFireAuth,
    private dataProvider: DataService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.platform.backButton.subscribe(() => null);

      //LOCALE TESTING INDIVIDUAL PAGE
      // this.router.navigateByUrl('privacy-policy', { replaceUrl: true, skipLocationChange: true })
      // IF SERVER NEEDS TO LOAD SUPPORT SECTION OF WEBSITE
      if(window.location.href.indexOf("support") > -1){
        return;
      }else{
        this.router.navigateByUrl('entry/login', { replaceUrl: true, skipLocationChange: true })
      .then(()=>{
        this.afAuth.auth.onAuthStateChanged(user => {
          if (user == null){
            this.router.navigateByUrl('entry/login', { replaceUrl: true, skipLocationChange: true })
            return;
          }else{
            if(user.email == null){
              this.router.navigateByUrl('onboarding/choose-username', { replaceUrl: true, skipLocationChange: true })
              // this.router.navigateByUrl('entry/facebook-verification', { replaceUrl: true, skipLocationChange: true }) // Disabled for sake of local testing.
            }else if(user.displayName == null){ // Check if user have username
              this.router.navigateByUrl('onboarding/choose-username', { replaceUrl: true, skipLocationChange: true })
              this.dataProvider.getCurrentUser().snapshotChanges().subscribe((user) => {
              this.user = user.payload.val();
                if(this.user.secretVault.secrets != undefined){
                  if(this.user.secretVault.secrets.secret1 != undefined){ // Check if user has secret 1
                    this.router.navigateByUrl('onboarding/congratulations', { skipLocationChange: true, replaceUrl: true })
                  }else{
                    this.router.navigateByUrl('onboarding/add-secret-first', { skipLocationChange: true, replaceUrl: true })
                  }
                }
              })
            }else{
              this.router.navigateByUrl('/tabs/tab2', { skipLocationChange: true, replaceUrl: true })
              // this.router.navigateByUrl('pricing', { skipLocationChange: true, replaceUrl: true })
            }
          }
        })
      })
    }
      this.splashScreen.hide();
    });
  }
}
