import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireAction } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  userUID;
  userPoints;

  constructor(
    private afAuth: AngularFireAuth,
    private afdb: AngularFireDatabase
  ) {

    if (this.afAuth.auth.currentUser != null){
      this.userUID = this.afAuth.auth.currentUser.uid;
      console.log('We got user ID from autehtication:', "Sanmi", this.userUID)
    // }else{
    //   this.userUID = JSON.parse(localStorage.getItem('userUID'));
    //   console.log('We got user ID from local storage:', "sanmi", this.userUID)
    }

   }



  // Get all users
  getUsers() {
    return this.afdb.list('/accounts', ref => ref.orderByChild('name'));
  }

  // Get user with username
  getUserWithUsername(username) {
    return this.afdb.list('/accounts', ref => ref.orderByChild('username').equalTo(username));
  }

  // Get user with username
  getUserWithPhoneNumber(telephone) {
    return new Promise((resolve)=>{
      // var userId = firebase.auth().currentUser.uid;
      // return firebase.database().ref('/users/' + userId).once('value').then(function(snapshot) {
      //   var username = (snapshot.val() && snapshot.val().username) || 'Anonymous';
      //   // ...
      // });
   this.afdb.database.ref('/accounts' ).orderByChild('phoneNumber').equalTo(telephone).once('value').then(res=>{
     resolve(res.val());
   })

  

   // return this.afdb.list('/accounts', ref => ref.orderByChild('phoneNumber').equalTo(telephone))
   
  //  .snapshotChanges().subscribe(res=>{
  //       res.forEach(element=>{
  //         // resolve(element.payload.val())
  //         return element.payload.val();
  //       });
  //     })
    })
  }

  getUserPoints(){
    return new Promise((resolve)=>{
   this.getCurrentUser().snapshotChanges().subscribe((user: any) => {
      user = user.payload.val();
      if(user.userPoints != undefined){
        this.userPoints = user.userPoints;
        if(this.userPoints == 0){ resolve ('<div class="point-amount flex self-center m-auto"><img src="../../assets/images/money.png"><span>0 Point</span></div><div class="points-note">Refill Now</div>')}
        if(this.userPoints == 1){ resolve ('<div class="point-amount flex self-center m-auto"><img src="../../assets/images/money.png"><span>1 Point</span></div><div class="points-note">Refill Now</div>')}
        if(this.userPoints > 1 && this.userPoints < 10 ){resolve  ('<div class="point-amount flex self-center m-auto"><img src="../../assets/images/money.png"><span>'+this.userPoints +' Points</span></div><div class="points-note">Refill Soon</div>')}
        if(this.userPoints >= 10 ){resolve  ('<div class="point-amount flex self-center m-auto"><img src="../../assets/images/money.png"><span>'+this.userPoints+' Points</span></div>')}
      }else{
        resolve ('<div class="point-amount flex self-center m-auto"><img src="../../assets/images/money.png"><span>Get Points</span></div>')
      }
    })
  })
  }

  // Get logged in user data
  getCurrentUser() {
    // console.log("Trying to get userId", this.afAuth.auth.currentUser.uid, this.userUID)
    return this.afdb.object('/accounts/' + this.afAuth.auth.currentUser.uid);
  }

  // Get user by their userId
  getUser(userId) {
    return this.afdb.object('/accounts/' + userId);
  }

  // Get requests given the userId.
  getRequests(userId) {
    return this.afdb.object('/requests/' + userId);
  }

  getNumOfViews(userId) {
    return this.afdb.object('/secretViews/' + userId);
  }

  // Get friend requests given the userId.
  getFriendRequests(userId) {
    return this.afdb.list('/requests', ref => ref.orderByChild('receiver').equalTo(userId));
  }

  // Get conversation given the conversationId.
  getConversation(conversationId) {
    return this.afdb.object('/conversations/' + conversationId);
  }

  // Get conversations of the current logged in user.
  getConversations() {
    return this.afdb.list('/accounts/' + this.afAuth.auth.currentUser.uid + '/conversations');
  }

  // Get messages of the conversation given the Id.
  getConversationMessages(conversationId) {
    return this.afdb.object('/conversations/' + conversationId + '/messages');
  }

  // Get messages of the group given the Id.
  getGroupMessages(groupId) {
    return this.afdb.object('/groups/' + groupId + '/messages');
  }

  // Get groups of the logged in user.
  getGroups() {
    return this.afdb.list('/accounts/' + this.afAuth.auth.currentUser.uid + '/groups');
  }

  // Get group info given the groupId.
  getGroup(groupId) {
    return this.afdb.object('/groups/' + groupId);
  }

  getBlockedLists() {
    return this.afdb.list('/accounts/' + this.afAuth.auth.currentUser.uid + '/conversations', ref => ref.orderByChild('blocked').equalTo(true));
  }

  saveFacebookData(uid, email, picture, first_name, last_name, date, gender, dob){
    this.afdb.object('/accounts/' + uid).update({
      email: email,
      img: picture,
      first_name: first_name,
      last_name: last_name,
      fb_verified_date: date,
      gender: gender,
      dob: dob
    })
  }


  saveUsername(username){
    this.afAuth.auth.currentUser.updateProfile({displayName: username});
    return this.afdb.object('/accounts/' + this.afAuth.auth.currentUser.uid).update({
      username: username
    })
  }
}
