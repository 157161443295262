import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { GlobalConstants } from '../../../theme/global-constants';


@Component({
  selector: 'app-location-required',
  templateUrl: './location-required.page.html',
  styleUrls: ['./location-required.page.scss'],
})
export class LocationRequiredModalPage implements OnInit {
  androidDevice: boolean
  iosDevice: boolean;
  browserDevice: boolean;
  iosDeviceType: string;
  browserOpera: boolean;
  browserSafari: boolean;
  browserChrome: boolean;
  browserIE: boolean;
  appName = GlobalConstants.appName;

  constructor(private modalController: ModalController,   private platform: Platform) { }

  ngOnInit() {
    if(this.platform.is("desktop") ) {
      this.browserDevice = true;
      if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
        this.browserOpera = true;
      } else if (navigator.userAgent.indexOf("Chrome") != -1) {
        this.browserChrome = true;
      } else if (navigator.userAgent.indexOf("Safari") != -1) {
        this.browserSafari = true;
      } else if (navigator.userAgent.indexOf("Firefox") != -1) {
          this.browserSafari = true;
      } else if ((navigator.userAgent.indexOf("MSIE") != -1)) {
          this.browserIE = true;
      } else {
          return 'unknown';
      }

      // Remember to develop the tutorial for Firefox and Opera browsers later.

    }

    if(this.platform.is("android")){
      this.androidDevice = true;
    }

    if(this.platform.is("iphone") || this.platform.is("ipad") ){
      this.iosDevice = true;
      if(this.platform.is("ipad")){
        this.iosDeviceType = "iPad"
      }else{
        this.iosDeviceType = "iPhone"
      }
    }
  }

  dismissProfilePrivacyModal() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data.
    this.modalController.dismiss({
      'dismissed': true
    });
  }
  

  

}
