import { InjectionToken, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './entry/error/not-found.component';

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');
const deactivateGuard = new InjectionToken('deactivateGuard');

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  { path: 'friends', loadChildren: './friends/friends.module#FriendsPageModule' },
  { path: 'group/:id', loadChildren: './group/group.module#GroupPageModule' },
  { path: 'addmembers/:id', loadChildren: './addmembers/addmembers.module#AddmembersPageModule' },
  { path: 'groupinfo/:id', loadChildren: './groupinfo/groupinfo.module#GroupinfoPageModule' },
  { path: 'groups', loadChildren: './groups/groups.module#GroupsPageModule' },
  { path: 'imagemodal', loadChildren: './modals/imagemodal/imagemodal.module#ImagemodalPageModule' },
  { path: 'message/:id', loadChildren: './message/message.module#MessagePageModule' },
  { path: 'messages', loadChildren: './messages/messages.module#MessagesPageModule' },
  { path: 'newgroup', loadChildren: './newgroup/newgroup.module#NewgroupPageModule' },
  { path: 'blockedlist', loadChildren: './blockedlist/blockedlist.module#BlockedlistPageModule' },
  { path: 'userinfo/:id', loadChildren: './userinfo/userinfo.module#UserinfoPageModule' },
  { path: 'profile', loadChildren: './profile/profile.module#ProfilePageModule' },
  { path: 'profile-privacy', loadChildren: './modals/profile-privacy/profile-privacy.module#ProfilePrivacyPageModule' },
  { path: 'edit-secret', loadChildren: './edit-secret/edit-secret.module#EditSecretPageModule' },
  { path: 'swipe', loadChildren: './swipe/swipe.module#SwipePageModule' },
  { path: 'contact-us', loadChildren: './contact-us/contact-us.module#ContactUsPageModule' },
  { path: 'entry/login', loadChildren: './entry/login/login.module#LoginPageModule' },
  { path: 'home', loadChildren: './entry/login/login.module#LoginPageModule' },
  { path: 'entry/location-required', loadChildren: './entry/location-required/location-required.module#LocationRequiredPageModule' },
  // { path: 'entry/register', loadChildren: './entry/register/register.module#RegisterPageModule' },
  { path: 'entry/forgot', loadChildren: './entry/forgot/forgot.module#ForgotPageModule' },
  { path: 'entry/confirm-sms/:telephone/:captchaWidgetId', loadChildren: './entry/confirm-sms/confirm-sms.module#ConfirmSmsPageModule' },
  { path: 'entry/facebook-verification', loadChildren: './entry/facebook-verification/facebook-verification.module#FacebookVerificationPageModule' },
  { path: 'onboarding/create-vault', loadChildren: './onboarding/create-vault/create-vault.module#CreateVaultPageModule' },
  { path: 'onboarding/add-secret-first', loadChildren: './onboarding/add-secret-first/add-secret-first.module#AddSecretFirstPageModule' },
  { path: 'onboarding/add-secret-second', loadChildren: './onboarding/add-secret-second/add-secret-second.module#AddSecretSecondPageModule' },
  { path: 'onboarding/add-secret-third', loadChildren: './onboarding/add-secret-third/add-secret-third.module#AddSecretThirdPageModule' },
  { path: 'onboarding/choose-username', loadChildren: './onboarding/choose-username/choose-username.module#ChooseUsernamePageModule' },
  { path: 'onboarding/congratulations', loadChildren: './onboarding/congratulations/congratulations.module#CongratulationsPageModule' },
  { path: 'onboarding/finish-profile', loadChildren: './onboarding/finish-profile/finish-profile.module#FinishProfilePageModule' },
  { path: 'location-required', loadChildren: './modals/location-required/location-required.module#LocationRequiredPageModule' },
  { path: 'support/privacy-policy', loadChildren: './support/privacy-policy/privacy-policy.module#PrivacyPolicyPageModule' },
  { path: 'support/terms-and-conditions', loadChildren: './support/terms-and-conditions/terms-and-conditions.module#TermsAndConditionsPageModule' },
  { path: 'make-payment', loadChildren: './make-payment/make-payment.module#MakePaymentPageModule' },

  // Payment Route
  {
    path: 'externalRedirect',
    canActivate: [externalUrlProvider],
    // We need a component here because we cannot define the route otherwise
    component: NotFoundComponent,
  },
  { path: 'pricing', loadChildren: './pricing/pricing.module#PricingPageModule' }

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
  providers:[
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
          
          const externalUrl = route.paramMap.get('externalUrl');
          window.open(externalUrl, '_self');
      },
    },
    {
      provide: deactivateGuard,
      useValue: () => {
        console.log('Guard function is called!');
        return false;
      }
    },
  ]
})
export class AppRoutingModule { }
