import { LocationRequiredModalPage } from './../modals/location-required/location-required.page';
import { Injectable } from '@angular/core';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Geolocation, GeolocationOptions, Geoposition } from '@ionic-native/geolocation/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { LoadingService } from './loading.service';
import { Platform, ModalController } from '@ionic/angular';
import { HTTP } from '@ionic-native/http/ngx';
import { HttpClient } from '@angular/common/http';



@Injectable()

export class LocationService {
    options: GeolocationOptions;
    currentPos: Geoposition;
    subscription: any;
    locationCoords: any;
    apiResponse: any;
    userLocation = [];
    
constructor(
    private diagnostic: Diagnostic,
    private androidPermissions: AndroidPermissions,
    private geolocation: Geolocation,
    private locationAccuracy: LocationAccuracy,
    private loadingProvider: LoadingService,
    private https: HTTP,
    private httpclient: HttpClient,
    private platform: Platform,
    private modalController: ModalController,
) {
   this.locationCoords = {
       latitude: "",
       longitude: "",
       accuracy: "",
       timestamp: ""
    }
}


getUserLocationCountry(latitudeLongitude){
   return new Promise(resolve => { 
      // latitudeLongitude = '7.4307748,3.921471';
      // if(!this.platform.is('mobile')){
         // console.log("Value of our lat and long on web", latitudeLongitude);
         this.httpclient.get('https://maps.googleapis.com/maps/api/geocode/json?latlng='+latitudeLongitude+'&key=AIzaSyBFatpZzWuvbyl9Tldg616a3G6LrHJlh7w', {responseType: 'json'})
         .subscribe((data: any) => {
           console.log("Detailed Location of User", data);
            data.results.forEach((a)=>{
               a.types.forEach((b)=>{
                  if(b == 'route'){this.userLocation['route']=a.formatted_address};
                  if(b == 'street_address'){this.userLocation['street_address']=a.formatted_address};
                  if(b == 'postal_code'){this.userLocation['postal_code']=a.formatted_address};
                  if(b == 'colloquial_area'){this.userLocation['colloquial_area']=a.formatted_address};
                  if(b == 'sublocality'){this.userLocation['sublocality']=a.formatted_address};
                  if(b == 'locality'){this.userLocation['locality']=a.formatted_address};
                  if(b == 'administrative_area_level_1'){this.userLocation['administrative_area_level_1']=a.formatted_address};
                  if(b == 'administrative_area_level_2'){this.userLocation['administrative_area_level_2']=a.formatted_address};
                  if(b == 'administrative_area_level_3'){this.userLocation['administrative_area_level_3']=a.formatted_address};
                  if(b == 'administrative_area_level_4'){this.userLocation['administrative_area_level_4']=a.formatted_address};
                  if(b == 'administrative_area_level_5'){this.userLocation['administrative_area_level_5']=a.formatted_address};
                  if(b == 'political'){this.userLocation['political']=a.formatted_address};
                  if(b == 'country'){this.userLocation['country']=a.formatted_address, this.userLocation['shortname']=a.address_components[0]['short_name']};
               })
            })
         resolve(this.userLocation);
         })
         // .catch(error => {
         //    console.log("Location Error:", error.error);
         //    this.loadingProvider.hide();
         // });
         
      // }else{
      // console.log("Value of our lat and long on mobile", latitudeLongitude)
      // this.https.get('https://maps.googleapis.com/maps/api/geocode/json?latlng='+latitudeLongitude+'&key=AIzaSyBFatpZzWuvbyl9Tldg616a3G6LrHJlh7w', {responseType: 'json'}, {method:'get'})
      // .then(data => {
      //   console.log("Detailed Location of User", JSON.parse(data.data))
      //    JSON.parse(data.data).results.forEach((a)=>{
      //       a.types.forEach((b)=>{
      //          if(b == 'route'){this.userLocation['route']=a.formatted_address};
      //          if(b == 'street_address'){this.userLocation['street_address']=a.formatted_address};
      //          if(b == 'postal_code'){this.userLocation['postal_code']=a.formatted_address};
      //          if(b == 'colloquial_area'){this.userLocation['colloquial_area']=a.formatted_address};
      //          if(b == 'sublocality'){this.userLocation['sublocality']=a.formatted_address};
      //          if(b == 'locality'){this.userLocation['locality']=a.formatted_address};
      //          if(b == 'administrative_area_level_1'){this.userLocation['administrative_area_level_1']=a.formatted_address};
      //          if(b == 'administrative_area_level_2'){this.userLocation['administrative_area_level_2']=a.formatted_address};
      //          if(b == 'administrative_area_level_3'){this.userLocation['administrative_area_level_3']=a.formatted_address};
      //          if(b == 'administrative_area_level_4'){this.userLocation['administrative_area_level_4']=a.formatted_address};
      //          if(b == 'administrative_area_level_5'){this.userLocation['administrative_area_level_5']=a.formatted_address};
      //          if(b == 'political'){this.userLocation['political']=a.formatted_address};
      //          if(b == 'country'){this.userLocation['country']=a.formatted_address, this.userLocation['shortname']=a.address_components[0]['short_name']};
      //       })
      //    })
      // resolve(this.userLocation);
      })
      .catch(error => {
         console.log("Location Error:", error);
         this.presentLocationRequired();
         this.loadingProvider.hide();
      });
      // }
      
   // })
 }


getUserLocation(city){
   this.https.get('https://maps.googleapis.com/maps/api/place/autocomplete/json?input=' + city + '&types=(cities)&key=AIzaSyBFatpZzWuvbyl9Tldg616a3G6LrHJlh7w', {responseType: 'json'}, {method:'get'})
   .then(data => {
     console.log(JSON.parse(data.data))
   })
   .catch(error => {
     console.log(error.error); 
   });
 }

//To check whether Location Service is enabled or Not
async locationStatus() {
    return new Promise((resolve, reject) => {
       this.diagnostic.isLocationEnabled().then((isEnabled) => {
       console.log(isEnabled);
       if (isEnabled === false) {
          resolve(false);
       } else if (isEnabled === true) {
          resolve(true);
       }
     })
   .catch((e) => {
      console.log(e);
   // this.showToast('Please turn on Location');
   reject(false);
   });
  });
 }


async checkLocationEnabled() {
     return new Promise((resolve, reject) => {
       this.diagnostic.isLocationEnabled().then((isEnabled) => {
          console.log(isEnabled);
          if (isEnabled === false) {
             this.loadingProvider.showToast('Please turn on Location Service');
             resolve(false);
          } else if (isEnabled === true) {
             this.checkGPSPermission().then((response) => {
             console.log(response, 'checkGPSPermission-checkLocationEnabled');
             this.apiResponse = response;
             if(this.apiResponse === false) {
                reject(false);
             } else {
                resolve(this.apiResponse);
             }
           })
          .catch((e) => {
             console.log(e, 'checkGPSPermission-checkLocationEnabled');
             reject(false);
        });
      }
    })
     .catch((e) => {
        console.log(e);
             this.loadingProvider.showToast('Please turn on Location');
             reject(false);
     });
   });
  }
//Check if application having GPS access permission

async checkGPSPermission() {
   return new Promise((resolve, reject) => {
      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION).then(
   result => {
     console.log(result.hasPermission);
     if (result.hasPermission) {
         console.log('hasPermission-YES');
        //If having permission show 'Turn On GPS' dialogue
        this.askToTurnOnGPS().then((response) => {
          console.log(response, 'askToTurnOnGPS-checkGPSPermission');
        if (this.apiResponse === false) {
           reject(this.apiResponse);
        } else {
           resolve(this.apiResponse);
        }
      });
    } else {
      console.log('hasPermission-NO');
      //If not having permission ask for permission
      this.requestGPSPermission().then((response) => {
         console.log(response, 'requestGPSPermission-checkGPSPermission');
         this.apiResponse = response;
         if (this.apiResponse === false) {
            reject(this.apiResponse);
         } else {
            resolve(this.apiResponse);
         }
       });
      }
    },
    err => {
       console.log("We are having a location permission issue", err)
      reject(false);
    });
 });
}

async requestGPSPermission() {
   return new Promise((resolve, reject) => {
   this.locationAccuracy.canRequest().then((canRequest: boolean) => {
   if (canRequest) {
      console.log("4");
   } else {
    //Show 'GPS Permission Request' dialogue
       this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION).then(() => {
    // call method to turn on GPS
    this.askToTurnOnGPS().then((response) => {
       console.log(response, 'askToTurnOnGPS-requestGPSPermission');
       this.apiResponse = response;
       if (this.apiResponse === false) {
         reject(this.apiResponse);
       } else {
         resolve(this.apiResponse);
       }
     }).catch(err=>{console.log(err)});
   },
    error => {
       this.loadingProvider.hide();
     //Show alert if user click on 'No Thanks'
   //   alert('requestPermission Error requesting location permissions ' + error);
    reject(false);
    }).catch(err=>{console.log(err)});
   }
  }).catch(err=>{console.log(err)});
 }).catch(err=>{console.log(err)});
}

async askToTurnOnGPS() {
   return new Promise((resolve, reject) => {
   this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then((resp) => {
   console.log(resp, 'location accuracy');
  // When GPS Turned ON call method to get Accurate location coordinates
  if(resp['code'] === 0) {
     resolve(this.apiResponse);
     this.getLocationCoordinates().then((cords) => {
       console.log(cords, 'coords');
       this.apiResponse = cords;
       if(this.apiResponse === false) {
          reject(false);
       } else {
          resolve(this.apiResponse);
       }
     }).catch(err=>{console.log(err)});
    }
     error => {
      console.log(error)
      // alert('Error requesting location permissions');
      reject(false);
     }
   });
 });
}

async getLocationCoordinates() {
   return new Promise((resolve, reject) => {
     this.geolocation.getCurrentPosition().then((resp) => {
     this.locationCoords.latitude = resp.coords.latitude;
     this.locationCoords.longitude = resp.coords.longitude;
     this.locationCoords.accuracy = resp.coords.accuracy;
     this.locationCoords.timestamp = resp.timestamp;
     console.log(resp, 'get locc');
     resolve(this.locationCoords);
  }).catch((error) => {
     console.log(error)

   //   alert('Error getting location');
     reject(false);
   });
  });
 }

 
async presentLocationRequired() {
    const modal = await this.modalController.create({
      component: LocationRequiredModalPage
    });
    return await modal.present();
  }
 

}