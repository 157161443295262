import { Injectable } from '@angular/core';
import { LoadingController, ToastController, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Facebook } from '@ionic-native/facebook/ngx';



@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loading;

  constructor(
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController,
    private alertCrtl: AlertController,
    private router: Router,
    private facebook: Facebook,
  ) { }

  show() {
    if (!this.loading) {
      this.loadingCtrl.create({ spinner: 'circles', duration: 2000 }).then(res => {
        this.loading = res;
        this.loading.present();
      })
    }

  }
  hide() {
    if (this.loading) {
      this.loading.dismiss();
      this.loading = null;
    }
  }

  showMessage(message) {
    if (!this.loading) {
      this.loadingCtrl.create({ 
        spinner: 'circles', 
        message: message, 
        cssClass: 'showToastBackground',
        showBackdrop: true,
        // duration: 600 
      }).then(res => {
        this.loading = res;
        this.loading.present();
      })
    }

  }

  showToast(message) {
    this.toastCtrl.create({ 
      message: message, 
      duration: 6000,
      position: 'middle',
      cssClass: 'showToastBackground',
      keyboardClose: true

    }).then(r => r.present())
  }

  showToastNoBackground(message) {
    this.toastCtrl.create({ 
      message: message, 
      duration: 3000,
      position: 'middle',
      translucent: false,
      keyboardClose: true

    }).then(r => r.present())
  }
  

  showFacebookError(message) {
    this.alertCrtl.create({ 
      header: 'Verification Failed',
      message: message, 
      translucent: true,
      cssClass:  "showErrorWithButtons",
      buttons: [
        {
          text: 'Not this Facebook? logout of your facebook on another tab and try again.',
          handler: () => {
            console.log('Not this Facebook?');
            this.facebook.logout();
          }
        }
        // , {
        //   text: 'Not this Phone?',
        //   handler: () => {
        //     this.router.navigateByUrl('entry/login')
        //     console.log('Change Phone Number');
        //   }
        // },
        // {
        //   text: 'Report Issue?',
        //   handler: () => {
        //     // this.router.navigateByUrl('entry/login')
        //     console.log('Report A Problem');
        //   }
        // }
      ]
    
    }).then(r => r.present())
  }

  showFacebookErrorContinue(message, continueURL) {
    this.alertCrtl.create({ 
      header: 'Verification Failed',
      message: message, 
      translucent: true,
      cssClass:  "showErrorWithButtons",
      buttons: [
        // {
        //   text: 'Not this Facebook? logout of your facebook on another tab and try again.',
        //   handler: () => {
        //     console.log('Not this Facebook?');
        //     this.facebook.logout();
        //   }
        // }
        , {
          text: 'Continue',
          handler: () => {
            this.router.navigateByUrl(continueURL)
          }
        },
        // {
        //   text: 'Report Issue?',
        //   handler: () => {
        //     // this.router.navigateByUrl('entry/login')
        //     console.log('Report A Problem');
        //   }
        // }
      ]
    
    }).then(r => r.present())
  }

  reportError(message) {
    this.alertCrtl.create({ 
      header: 'Error',
      message: message, 
      translucent: true,
      cssClass:  "reportErrorAlert",
      buttons: [
        {
          text: 'Report This Problem',
          handler: () => {
            console.log('Build  A Report Error Page.');
            // this.facebook.logout();
          }
        }
      ]
    
    }).then(r => r.present())
  }

  success() {
    return new Promise(resolve => { 
      if (!this.loading) {
      this.loadingCtrl.create({
        spinner: null, 
        message: '<div><img class="mb-4 w-32 mx-auto" src="../../assets/images/success-icon.png" alt="Success"></div>', 
        duration: 1700,
        cssClass:'loading-success-alert',
        backdropDismiss: true
      })
      .then(res => {
        this.loading = res;
        this.loading.present();
        resolve(true);
      })
    }
  })
  }
}
