import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { environment } from '../environments/environment.prod';

import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { Facebook } from '@ionic-native/facebook/ngx';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { MediaCapture } from '@ionic-native/media-capture/ngx';
import { File } from '@ionic-native/file/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Contacts } from '@ionic-native/contacts/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';


import { SharedModule } from './shared-components/share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


// Added by Sanmi Ayotunde
// Geolocation:
import { BackgroundGeolocation } from '@ionic-native/background-geolocation/ngx';
// Location Accuracy
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
// Android Permissions
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { LocationService } from './services/location.service';
import { HTTP } from '@ionic-native/http/ngx';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
// Modals
import { ImagemodalPageModule } from './modals/imagemodal/imagemodal.module';
import { ProfilePrivacyPageModule } from './modals/profile-privacy/profile-privacy.module';
import { LocationRequiredPageModule } from './modals/location-required/location-required.module';

// Stripe
import { Stripe } from '@awesome-cordova-plugins/stripe/ngx';

// External URL Directive
import { ExternalUrlDirective } from './directives/external-url.directives';
import { NotFoundComponent } from './entry/error/not-found.component';
import { NoSanitizePipe } from './pipes/nosanitizer';

@NgModule({
  declarations: [
    AppComponent, NotFoundComponent, ExternalUrlDirective,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'md',
    }),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ImagemodalPageModule,
    LocationRequiredPageModule,
    ProfilePrivacyPageModule,
    HttpClientModule,
    // FontAwesomeModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    GooglePlus,
    Facebook,
    Camera,
    MediaCapture,
    File,
    FirebaseX,
    Geolocation,
    Contacts,
    Keyboard,

    // Added by Sanmi
    BackgroundGeolocation,
    LocationAccuracy,
    AndroidPermissions,
    Diagnostic,
    LocationService,
    Stripe,
    HTTP,
    NoSanitizePipe, //Used on the pricing page.

    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }