export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyD9EYlgM_dOIeiYwq8ta1QfWlhojSyySZs",
    authDomain: "secrecy-dating-app.firebaseapp.com",
    databaseURL: "https://secrecy-dating-app.firebaseio.com",
    projectId: "secrecy-dating-app",
    storageBucket: "secrecy-dating-app.appspot.com",
    messagingSenderId: "947043501344",
    appId: "1:947043501344:web:7f907b8e3046e994a6c345",
    measurementId: "G-C2GNDHNC7Y"
  },
  googleClientId: "947043501344-fb0gct9as6u0km1omk76qjc96bqi5vf0.apps.googleusercontent.com",
  stripeTestingPerishableKey: 'pk_test_3olPRCu4igxUhoLCASmKW1UY',
  stripeTestingSecret: 'sk_test_T4QUyQc15MNDD14puniRzwBe',
  stripeLivePershableKey: 'pk_live_fHjiNyV8rTRRuCnVZlAAiLN0',
  stripeLiveSecret: 'sk_live_LNCLr8PifucSGZVrD4NEyAr9'
};

