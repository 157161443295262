import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-onboarding',
  templateUrl: './header-onboarding.component.html',
  styleUrls: ['./header-onboarding.component.css']
})
export class HeaderOnboardingComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  goToChooseUsername(){
    this.router.navigate(['/onboarding/choose-username']);
  }

  goToCreateVault(){
    this.router.navigate(['/onboarding/create-vault']);
  }

  goToAddSecretFirst(){
    this.router.navigate(['/onboarding/add-secret-first']);
  }

}
