import { NgModule } from '@angular/core';
import { ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FriendPipe } from '../pipes/friend';
import { ConversationPipe } from '../pipes/conversation';
import { GroupPipe } from '../pipes/group';
import { SearchPipe } from '../pipes/search';
import { DateFormatPipe } from '../pipes/date';
import { HeaderHomeUserNotLoginComponent } from '../shared-components/header-home-user-not-login/header-home-user-not-login.component';
import { HeaderLogoOnlyComponent } from '../shared-components/header-logo-only/header-logo-only.component';
import { HeaderOnboardingComponent } from '../shared-components/header-onboarding/header-onboarding.component';
import { FooterGlobalComponent } from '../shared-components/footer-global/footer-global.component';
import { DesktopLeftnavComponent } from './desktop-leftnav/desktop-leftnav.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        FriendPipe,
        ConversationPipe,
        GroupPipe,
        SearchPipe,
        DateFormatPipe,
        HeaderHomeUserNotLoginComponent,
        HeaderLogoOnlyComponent,
        HeaderOnboardingComponent,
        FooterGlobalComponent,
        DesktopLeftnavComponent
    ],
    exports: [
        FriendPipe,
        ConversationPipe,
        GroupPipe,
        SearchPipe,
        DateFormatPipe,
        HeaderHomeUserNotLoginComponent,
        HeaderLogoOnlyComponent,
        HeaderOnboardingComponent,
        FooterGlobalComponent,
        DesktopLeftnavComponent
    ], 
    schemas: [CUSTOM_ELEMENTS_SCHEMA, ErrorHandler]
})

export class SharedModule { }