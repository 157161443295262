import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'noSanitizePipe' })
export class NoSanitizePipe implements PipeTransform {
  constructor(private noSanitizePipe: DomSanitizer) { }

  transform(html: string): SafeHtml {
    return this.noSanitizePipe.bypassSecurityTrustHtml(html);
  }
}