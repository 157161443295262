import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-desktop-leftnav',
  templateUrl: './desktop-leftnav.component.html',
  styleUrls: ['./desktop-leftnav.component.scss'],
})
export class DesktopLeftnavComponent implements OnInit {

  friendRequestCount = 0;
  viewsCount = 0
  user: any;
  username:any;
  userUID;
  paymentURL;

  constructor(private router: Router, 
    private dataProvider: DataService,
    private afAuth: AngularFireAuth) { 
    // Use Local Storage for UserID if currentUser object cannot be fetched.
    if (this.afAuth.auth.currentUser != undefined){
      this.userUID = this.afAuth.auth.currentUser.uid;
    }else{
      this.userUID = JSON.parse(localStorage.getItem('userUID'));
    }
  }

  ngOnInit() {
    this.dataProvider.getCurrentUser().snapshotChanges().subscribe((user: any) => {
      if (user != null){
        this.user = user.payload.val();
        this.paymentURL = "https://payment.secrecyapp.com/?email=" + this.user.email;
        console.log("Payment URL:", this.paymentURL)
      }
    })
    }
  

  ionViewDidEnter() {
    this.dataProvider.getCurrentUser().snapshotChanges().subscribe((user: any) => {
      if (user != null){
        this.user = user.payload.val();

        // CHECK THE NUMBER OF FRIEND REQUESTSW.
        this.dataProvider.getRequests(this.afAuth.auth.currentUser.uid).snapshotChanges().subscribe((requestsRes: any) => {
          let requests = requestsRes.payload.val();
          console.log("Request made to server", requests);
          if (requests != null) {
            if (requests.friendRequests != null && requests.friendRequests != undefined)
              this.friendRequestCount = requests.friendRequests.length;
            else this.friendRequestCount = 0
          }
          else this.friendRequestCount = 0;
        });

        // CHECK THE NUMBER OF VIEWS .
        this.dataProvider.getNumOfViews(this.afAuth.auth.currentUser.uid).snapshotChanges().subscribe((requestsRes: any) => {
          let requests = requestsRes.payload.val();
          console.log("All Views:",requests);
          if (requests != null) {
            if (requests.viewsRecieved != null && requests.viewsRecieved != undefined){
              this.viewsCount = requests.viewsRecieved.length;
              console.log(this.viewsCount)
              }else{ this.viewsCount = 0}
          }
          else this.viewsCount = 0;
        });

      }
      })
    }

  goToFriendRequestPage(tabSegment){
    this.router.navigate(['/tabs/tab3', {tab:tabSegment}])
  }

  goToFriends(tabSegment){
    this.router.navigate(['/tabs/tab3', {tab:tabSegment}])
  }

  goToSwipe(){
    this.router.navigate(['/tabs/tab2'])
  }


  goToPricing(){
    this.router.navigate(['/pricing'])
  }

  gotoMessages(){
    this.router.navigate(['/tabs/tab1'])
  }

  gotoSecrets(){
    this.router.navigate(['/tabs/tab3'])
  
  }

  gotoViewPage(){}

  goToInterestPage(){}

  goToStatisticsPage(){}

  checkAccountBalance(){}

  buyPoints(){
     this.router.navigateByUrl('https://www.payment.secrecyapp.com');
  }

}
